
import './no_second.css'

export default function NoSecond() {
    return (
        <header className="App-header">
            <div>
                <h1>Okay</h1>
            </div>
            <br />
            <div>
                <img id="michaelCrying" src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExaDBpMTI0ZDF3cTg2b3Y3aWY2eHd4aWozZWU2aTRxMWk3dm4zNGxsNSZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/Ty9Sg8oHghPWg/giphy.gif"></img>
            </div>
            <div>
                <h2>Have a good day 🥲</h2>
            </div>
        </header >);
}