import './no_first.css'
import { Link } from "react-router-dom";
import { no, yes } from '../App'

export default function NoFirst() {
    return (
        <header className="App-header">
            <div>
                <h1>Sure ahno? 🥺</h1>
            </div>
            <br />
            <div>
                <img id="pussimg" src="https://i.giphy.com/media/v1.Y2lkPTc5MGI3NjExZXppeDh6OXJpcnZ4bXc5dzJid3cydXRpbThlaXZqYjRlMm4yOXVwYyZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/Q6WPVzFU8LcBWWgQE1/giphy-downsized-large.gif"></img>
            </div>
            <div>
                <h1></h1>
            </div>
            <div class="button-container">
                <div class="button-one">
                    <Link to="/yes"> <button class="button-first" onClick={yes}>I've changed my mind 🥹</button></Link>
                </div>
                <div class="button-one">
                    <Link to="/no_second"><button class="button-first" onClick={no}>Sure ahnnn 🥲</button></Link>
                </div>
            </div>

        </header >);
}