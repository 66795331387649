import './App.css';
import Question from './Pages/question';
import NoFirst from './Pages/no_first';
import NoSecond from './Pages/no_second';
import Home from './Pages/home';
import Yes from './Pages/yes';
import { Routes, Route } from 'react-router-dom';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Home />} />
        <Route element={<Question />} path="/question" />
        <Route element={<NoFirst />} path="/no_first" />
        <Route element={<NoSecond />} path="/no_second" />
        <Route element={<Yes />} path="/yes" />
      </Routes>

    </div>
  );
}

export function yes() {
  fetch('http://localhost:8080/response', {
    method: 'POST',
    mode: 'cors',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      'accepted': true
    })
  })
}

export function no() {
  fetch('http://localhost:8080/response', {
    method: 'POST',
    mode: 'cors',
    headers: {
      "Content-Type": "application/json"
    },
    body: JSON.stringify({
      'accepted': false
    })
  })
}

export default App;
