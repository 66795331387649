import './home.css'
import logo from '../logo.svg';
import { Link } from "react-router-dom";

export default function Home() {
    // const navigate = useNavigate();
    return (
        <header className="App-header">
            <div id="catimgdiv">
                <img src="https://media3.giphy.com/media/v1.Y2lkPTc5MGI3NjExZzU0MXkycXMyd2xiMjQ2cnQ0a3J1Nnpoa3Z5emFldHA5ejd5amdjcCZlcD12MV9pbnRlcm5hbF9naWZfYnlfaWQmY3Q9Zw/vFKqnCdLPNOKc/giphy.webp" id="cat-hello" alt="logo" />
            </div>
            <h1>
                Hey Shreya!
            </h1>
            {/* <br /> */}
            {/* <br /> */}
            <div id="qndiv">
                <h2 id="qn">So, <a href="https://www.instagram.com/joey____daniel/">the guy</a> who sent you this wants to ask you something... </h2>
            </div>
            <Link to="/question"><button
                className="button-first"
                href="https://reactjs.org"
                target="_blank"
                rel="noopener noreferrer"
            >
                🥺
                <br />
                👉 👈
            </button></Link>
        </header>
    );
}