import './yes.css'

export default function Yes() {
    return (
        <header className="App-header">
            <div>
                <img id="yesimg" src="https://c.tenor.com/w_xkJNZpzhgAAAAd/tenor.gif"></img>
            </div>
            <div>
                <h1>Yayyy</h1>
                <h3>P.S : You look cute :)</h3>
            </div>
        </header >);
}