import './question.css';
import { Link } from "react-router-dom";
import { yes } from '../App'

export default function Question() {
    return (
        <header className="App-header">
            <div id="threecoldiv">
                <div class="threebuttonclass">
                    <h2>Pros</h2>
                    <p>1. Princess treatment 👑</p>
                    <p>2. 10/10 Listener</p>
                    <p>3. Will play you music ♫</p>
                    <p>4. Cute (sometimes)</p>
                </div>
                <div class="threebuttonclass" id="pandaimgdiv">
                    <img id="panda" src="https://media1.tenor.com/m/2E63TvtkGB0AAAAd/red-panda-flowers.gif"></img>
                </div>
                <div class="threebuttonclass">
                    <h2>Cons</h2>
                    <p>1. Will annoy you</p>
                    <p>2. Might eat your food</p>
                </div>
            </div>
            <div id="valqndiv">
                <h1>Would you like to be my valentine? 💐</h1>
            </div>
            <div class="button-container">
                <div class="button-one">
                    <Link to="/yes"> <button class="button-first" onClick={yes}>Yes 😄</button></Link>
                </div>
                <div class="button-one">
                    <Link to="/no_first"><button class="button-first">No 😔</button></Link>
                </div>
            </div>
        </header >);
}